import PlaybackProgression from './PlaybackProgression';
import { AuthenticationHandler } from './AuthenticationHandler';
import { applyProgressionToMediaInformation } from './applyProgressionToMediaInformation';
import { applyUserInfoToMediaInformation } from './applyUserInfoToMediaInformation';
import { HlsLiveTime } from './hls-live-time';

export class MediaInformationApplier {
  private playbackProgression: PlaybackProgression;
  private hlsLiveTime: HlsLiveTime;
  private authenticationHandler: AuthenticationHandler;

  constructor(
    playbackProgression: PlaybackProgression,
    hlsLiveTime: HlsLiveTime,
    authenticationHandler: AuthenticationHandler
  ) {
    this.playbackProgression = playbackProgression;
    this.hlsLiveTime = hlsLiveTime;
    this.authenticationHandler = authenticationHandler;
  }

  applyTo(mediaInformation: cast.framework.messages.MediaInformation | undefined) {
    if (!mediaInformation) {
      return;
    }
    if (!mediaInformation.customData) {
      mediaInformation.customData = {};
    }
    applyProgressionToMediaInformation(mediaInformation, this.playbackProgression, this.hlsLiveTime);
    applyUserInfoToMediaInformation(mediaInformation, this.authenticationHandler);
  }
}
