export default function preloadImage(src: string): Promise<Event | void> {
  return new Promise((resolve, reject) => {
    if (src) {
      const img = new Image();
      img.onload = resolve;
      img.onerror = reject;
      img.src = src;
    } else {
      resolve();
    }
  });
}
