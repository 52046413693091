import { getLogger } from '../logging/logger';
import { SeekRequestCustomData } from './custom-data/seek-request-custom-data';
import { HlsLiveTime } from './hls-live-time';

const logger = getLogger('SeekRequestHandler');

export class SeekRequestHandler {
  private playerManager: cast.framework.PlayerManager;
  private hlsLiveTime: HlsLiveTime;

  constructor(playerManager: cast.framework.PlayerManager, hlsLiveTime: HlsLiveTime) {
    this.playerManager = playerManager;
    this.hlsLiveTime = hlsLiveTime;

    this.playerManager.setMessageInterceptor(cast.framework.messages.MessageType.SEEK, this.interceptSeek.bind(this));
  }

  interceptSeek(requestData: cast.framework.messages.SeekRequestData): cast.framework.messages.SeekRequestData {
    const currentTime = requestData.currentTime;
    const customData: SeekRequestCustomData = requestData.customData || {};

    logger.debug('interceptSeek', currentTime, customData);

    if (this.playerManager.getMediaInformation().streamType === cast.framework.messages.StreamType.LIVE) {
      const liveSeekTarget = this.getLiveSeekTarget(currentTime, customData);
      if (liveSeekTarget !== undefined) {
        requestData.currentTime = liveSeekTarget;
      }
    }

    if (typeof customData.relativeSeek === 'number') {
      requestData.relativeTime = customData.relativeSeek;
    }

    return requestData;
  }

  private getLiveSeekTarget(currentTime?: number, customData: SeekRequestCustomData = {}): number | undefined {
    if (customData.seekToTimestamp) {
      const timestampOffset = this.hlsLiveTime.isActive()
        ? this.hlsLiveTime.getStartAbsoluteTime() + this.hlsLiveTime.getCurrentTimeOffset()
        : this.playerManager.getStartAbsoluteTime();
      return customData.seekToTimestamp / 1000 - timestampOffset;
    }

    // Snap to end, given a minimum seekable range (randomly picked min length).
    const seekableRange = this.hlsLiveTime.isActive()
      ? this.hlsLiveTime.getLiveSeekableRange()
      : this.playerManager.getLiveSeekableRange();

    if (seekableRange.start === undefined || seekableRange.end === undefined) {
      return undefined;
    }

    if (seekableRange.end - seekableRange.start >= 180) {
      if (currentTime && currentTime > seekableRange.end - 60) {
        return Infinity;
      }
    }

    if (customData.seekToLive) {
      return Infinity;
    }

    return undefined;
  }
}
