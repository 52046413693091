import document from 'global/document';
import { Spinner } from 'spin.js';

const activeClassName = 'spinner--active';

enum SpinnerState {
  Hidden,
  FadeOut,
  FadeIn,
  Visible,
}

export interface SpinnerOverlayInterface {
  hide(): void;
  show(): void;
}

export default class SpinnerOverlay implements SpinnerOverlayInterface {
  private spinner: Spinner;
  private spinnerElem: HTMLElement;
  private state: SpinnerState;

  constructor() {
    const containerElem = document.querySelector<HTMLElement>('.gcast-container');
    const spinnerElem = containerElem?.querySelector<HTMLElement>('.spinner');
    if (containerElem === null || spinnerElem === null || spinnerElem == undefined) {
      throw new Error('.gcast-container or .spinner element missing');
    }
    this.state = spinnerElem.classList.contains(activeClassName) ? SpinnerState.Visible : SpinnerState.Hidden;

    this.spinnerElem = spinnerElem;

    this.spinner = new Spinner({
      className: 'spinner-el',
      color: '#fff',
      corners: 1,
      direction: 1,
      left: 'auto',
      length: 10,
      lines: 13,
      radius: 15,
      rotate: 0,
      shadow: false,
      speed: 1,
      top: 'auto',
      width: 5,
      zIndex: 2e9,
    });

    this.onTransitionEnd = this.onTransitionEnd.bind(this);
    this.spinnerElem.addEventListener('transitionend', this.onTransitionEnd);
  }

  hide() {
    if ([SpinnerState.Hidden, SpinnerState.FadeOut].includes(this.state)) {
      return;
    }

    this.state = SpinnerState.FadeOut;
    this.spinnerElem.classList.remove(activeClassName);
  }

  show() {
    if ([SpinnerState.FadeIn, SpinnerState.Visible].includes(this.state)) {
      return;
    }

    this.state = SpinnerState.FadeIn;
    if (!this.spinner.el) {
      this.spinner.spin(this.spinnerElem);
    }
    this.spinnerElem.classList.add(activeClassName);
  }

  private onTransitionEnd() {
    switch (this.state) {
      case SpinnerState.FadeOut:
        this.state = SpinnerState.Hidden;
        if (this.spinner.el) {
          this.spinner.stop();
        }
        break;

      case SpinnerState.FadeIn:
        this.state = SpinnerState.Visible;
        break;
    }
  }
}

export class DummySpinnerOverlay implements SpinnerOverlayInterface {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  hide() {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  show() {}
}
