import type { MediaItem } from '../../../types/nrk/mediaitem';
import { getLogger } from '../logging/logger';
import {
  Events as AnalyticsEvents,
  mediaTracker as importedMediaTracker,
  VendorConfig,
  VendorData,
  VendorType,
  VendorTypeMap,
  EventArgs as AnalyticsEventArgs,
  Adapter,
} from '@nrk/media-analytics';
import importedVendorSpringstreams from '@nrk/media-analytics/dist/vendor/scores/springstreams';
import { config } from '../appConfig';
import * as snowplow from '@nrk/snowplow-web/snowplow-bundle.js';
import { EventForwarder } from './EventForwarder';
import EventEmitter from 'eventemitter3';
import { NRKService } from '../nrkService';
import { EpgProgramSet } from '../NRKLiveEpg/EpgTrackerEvents';
import { isEuropeanSummerTime } from '@nrk/european-summer-time';
import { HlsLiveTime } from '../CafPlayer/hls-live-time';

const logger = getLogger('NrkMediaLogger');

export default class NrkMediaLogger {
  // These anys will be fixed with the latest @nrk/media-analytics
  private vendors: VendorTypeMap;
  private data: VendorData;
  private eventForwarder: EventForwarder;
  private emitter: EventEmitter<AnalyticsEventArgs>;
  private isChannel = false;
  private mediaId?: string;

  constructor(video: HTMLVideoElement, hlsLiveTime: HlsLiveTime, nrkService: NRKService) {
    this.vendors = {
      [VendorType.SCORES]: importedVendorSpringstreams,
      [VendorType.SNOWPLOW]: snowplow,
    };

    this.emitter = new EventEmitter<AnalyticsEventArgs>();
    this.eventForwarder = new EventForwarder(video, this.emitter);

    this.data = {
      // @ts-expect-error: Actual data comes later.
      [VendorType.SCORES]: {},
      [VendorType.SNOWPLOW]: {
        config: {
          // https://github.com/nrkno/snowplow-tooling
          appId: 'no.nrk.cast',
          collectorUrl: config.snowplowCollectorUrl,
          serviceId: config.snowplowServiceId[nrkService],
        },
      },
    };

    const getConfig = <T extends VendorType>(type: T): VendorConfig<T> => {
      return {
        getData: () => this.data[type],
        getLogger: () => logger.log.bind(logger),
        getVendor: () => this.vendors[type],
        playerName: 'NRK Chromecast',
        playerVersion: config.version,
      };
    };

    function getPosition() {
      if (hlsLiveTime.isActive()) {
        return hlsLiveTime.getCurrentTime() + hlsLiveTime.getStartAbsoluteTime();
      }

      return video.currentTime;
    }

    function getDuration() {
      // Is live stream?
      if (hlsLiveTime.isActive()) {
        return Date.now() / 1000;
      }

      return video.duration;
    }

    // adapter
    const adapter: Adapter = {
      getConfig,
      getDuration,
      getPosition,
      isChannel: () => this.isChannel,
      isLiveStream: () => hlsLiveTime.isActive(),
      getChannelId: () => this.mediaId,
      getLiveTimeAtPosZero: () => hlsLiveTime.getStartAbsoluteTime(),
    };

    importedMediaTracker(window, this.emitter, adapter);
  }

  prepare(mediaItem: MediaItem) {
    this.isChannel = mediaItem.isChannel;
    this.mediaId = mediaItem.id;

    this.eventForwarder.setupDelayedEvents();
    this.eventForwarder.attachForwardEvents();

    this.data[VendorType.SCORES] = mediaItem.scoresStatistics as (typeof this.data)[VendorType.SCORES];
    this.data[VendorType.SNOWPLOW].content = {
      id: mediaItem.id,
      source: mediaItem.snowplowStatistics?.source,
    };

    this.emitter.emit(AnalyticsEvents.DATA_CHANGED);
    this.emitter.emit(AnalyticsEvents.INTENT_TO_PLAY);

    if (!this.isChannel) {
      this.eventForwarder.start();
    }
  }

  stop() {
    this.eventForwarder.detachForwardEvents();
  }

  liveProgramChanged(epgProgramSet: EpgProgramSet): void {
    if (!this.isChannel) {
      return;
    }

    const programId = epgProgramSet.current?.programId;
    let utcStart: number;
    let utcEnd: number;

    if (epgProgramSet.current !== undefined) {
      utcStart = epgProgramSet.current.actualStart / 1000;
      utcEnd = epgProgramSet.current.actualEnd / 1000;
    } else {
      const dayBounds = this.getDayBoundsNorwayMs();

      if (epgProgramSet.previous !== undefined) {
        utcStart = epgProgramSet.previous.actualEnd / 1000;
      } else {
        utcStart = dayBounds.start / 1000;
      }
      if (epgProgramSet.next !== undefined) {
        utcEnd = epgProgramSet.next.actualStart / 1000;
      } else {
        utcEnd = dayBounds.end / 1000;
      }
    }

    this.emitter.emit(AnalyticsEvents.PROGRAM_CHANGED, { id: programId, utcStart, utcEnd });

    this.data[VendorType.SCORES] = {
      ...this.data[VendorType.SCORES],

      // Missing program ID is plugged with channel ID, as requested by the NRK
      // analytics team.
      springStreamProgramId: programId ?? this.mediaId,
    };

    this.emitter.emit(AnalyticsEvents.DATA_CHANGED);

    this.eventForwarder.start();
  }

  setUser(userId: string): void {
    this.data[VendorType.SNOWPLOW].userId = userId;
    this.emitter.emit(AnalyticsEvents.DATA_CHANGED);
  }

  clearUser(): void {
    this.data[VendorType.SNOWPLOW].userId = undefined;
    this.emitter.emit(AnalyticsEvents.DATA_CHANGED);
  }

  private getDayBoundsNorwayMs(): { start: number; end: number } {
    const hourInMs = 3600000;
    const dayInMs = hourInMs * 24;
    const dayStartUTC = Math.floor(Date.now() / dayInMs) * dayInMs;
    const dayEndUTC = dayStartUTC + dayInMs;

    // 00:00 UTC is either 01:00 or 02:00 in Norway. We want 00:00 in Norway,
    // which is 23:00 or 22:00 UTC.
    const dayStartNorwayUTC = dayStartUTC - (isEuropeanSummerTime(dayStartUTC) ? hourInMs * 2 : hourInMs);
    const dayEndNorwayUTC = dayEndUTC - (isEuropeanSummerTime(dayEndUTC) ? hourInMs * 2 : hourInMs);
    return { start: dayStartNorwayUTC, end: dayEndNorwayUTC };
  }
}
