import { getLogger } from '../logging/logger';
import config from '../appConfig';

const logger = getLogger('DebugPlayer');
const isProd = config.env === 'prod' || config.env === 'production';

export default class DebugPlayer {
  constructor(playerManager: cast.framework.PlayerManager, playerDataBinder: cast.framework.ui.PlayerDataBinder) {
    if (isProd) {
      return;
    }

    playerDataBinder.addEventListener(cast.framework.ui.PlayerDataEventType.ANY_CHANGE, (e) => {
      const { type, value, field } = e;
      if (type === cast.framework.ui.PlayerDataEventType.CURRENT_TIME_CHANGED) {
        return;
      }
      logger.log(type, value, field);
    });

    playerManager.addEventListener(cast.framework.events.EventType.ALL, (e, ...args: unknown[]) => {
      logger.log(e, ...args);
      return;
    });
  }
}
