import { ErrorDetails, default as ErrorObserver } from './ErrorObserver';
import { getLogger } from '../logging/logger';
import CustomMessageHandler from './CustomMessageHandler';
import ErrorOverlay from '../ui/ErrorOverlay';
import { AuthenticationHandler } from './AuthenticationHandler';

const ERROR_OVERLAY_DELAY = 1000;
const logger = getLogger('ErrorBehaviour');

export default class ErrorBehaviour {
  private errorObserver: ErrorObserver;
  private customMessageHandler: CustomMessageHandler;
  private errorOverlay: ErrorOverlay;
  private authenticationHandler: AuthenticationHandler;
  private showErrorOverlayTimeoutID?: number;

  constructor(
    errorObserver: ErrorObserver,
    customMessageHandler: CustomMessageHandler,
    errorOverlay: ErrorOverlay,
    authenticationHandler: AuthenticationHandler
  ) {
    this.errorObserver = errorObserver;
    this.customMessageHandler = customMessageHandler;
    this.errorOverlay = errorOverlay;
    this.authenticationHandler = authenticationHandler;

    this.errorObserver.listen(this.onPlayerError);
  }

  stopErrorOverlay() {
    if (this.showErrorOverlayTimeoutID) {
      window.clearTimeout(this.showErrorOverlayTimeoutID);
    }
  }

  private onPlayerError = (errorDetails: ErrorDetails) => {
    const { origin, error, errorData, nonPlayable } = errorDetails;

    const endUserErrorMessage = nonPlayable ? nonPlayable.message : 'Beklager noe gikk galt';

    let origins: string[] = [origin];
    if (errorData) {
      origins = origins.concat([errorData.type.toString(), errorData.reason ? errorData.reason.toString() : '']);
    }
    const originsPath = origins.join('/');

    logger.error(originsPath, error);

    this.customMessageHandler.sendError(`${endUserErrorMessage} (${originsPath})`, nonPlayable);

    this.stopErrorOverlay();
    this.showErrorOverlayTimeoutID = window.setTimeout(() => {
      const body = nonPlayable ? '' : 'Vennligst start på nytt eller prøv igjen senere';
      this.errorOverlay.update({
        title: endUserErrorMessage,
        body,
        reference: originsPath,
        user: this.authenticationHandler.userName ?? null,
      });
    }, ERROR_OVERLAY_DELAY);
  };
}
