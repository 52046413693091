import window from 'global/window';
import { ServiceID } from '@nrk/snowplow-web';

declare global {
  interface Window {
    globalConfig: GlobalConfig;
  }
}

interface GlobalConfig {
  enableSlideshow: boolean;
  googleAnalyticsAccount: string;
  apiBasePath: string;
  environment: string;
  version: string;
  routes?: { [name: string]: string };
  instrumentationKey: string;
  eeaPortability: boolean;
  snowplowCollectorUrl: string;
  debugging: boolean;
}

export interface AppConfig {
  env: string;
  googleAnalyticsKey: string;
  mediaRequestURL: string;
  version: string;
  apiBase: string;
  epgLiveBufferTemplate: string;
  liveElementsURL: string;
  redeemInviteCodeURL: string;
  refreshAccessTokenURL: string;
  profileSettingsURL: string;
  remoteLogUrl: string;
  enableSlideshow: boolean;
  slideshowSlideTime: number;
  slideshowDataURL: string;
  radioBackgroundTime: number;
  displaySlideShowAfterConnectTime: number;
  maxIdleTime: number;
  maxPauseTime: number;
  liveBufferWindow: number;
  getNewDataBeforeNextProgramBeginsInterval: number;
  waitToFetchLiveElementsTime: number;
  timeZoneOffset: number;
  debugging: boolean;
  instrumentationKey: string;
  eeaPortability: boolean;
  snowplowCollectorUrl: string;
  snowplowServiceId: { [name: string]: ServiceID };
}

// Calculate timezone offset
// This method does not work in all browsers but is in Chrome since v24 - tested on old chromecast
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
const osloTime = new Date().toLocaleString('nb-NO', { timeZone: 'Europe/Oslo' });
const osloHours = parseFloat(osloTime.split(' ')[1].split('.')[0]);
const timeZoneOffset = osloHours - new Date().getUTCHours();

const settings = window.globalConfig || {};
const routes = settings.routes || {};

export const config: AppConfig = {
  env: settings.environment,
  googleAnalyticsKey: settings.googleAnalyticsAccount,
  mediaRequestURL: `${settings.apiBasePath}/playback/metadata/`,
  remoteLogUrl: '//streamlog.azurewebsites.net/log/',
  version: settings.version,
  apiBase: settings.apiBasePath,
  epgLiveBufferTemplate: `${settings.apiBasePath}/channels/{channelId}/epglivebuffer`,
  slideshowDataURL: routes.headliners,
  liveElementsURL: routes.liveElements,
  redeemInviteCodeURL: routes.redeemInviteCode,
  refreshAccessTokenURL: routes.refreshAccessToken,
  profileSettingsURL: routes.profileSettings,
  enableSlideshow: settings.enableSlideshow,
  slideshowSlideTime: 8,
  radioBackgroundTime: 30,
  displaySlideShowAfterConnectTime: 10,
  maxIdleTime: 5 * 60,
  maxPauseTime: 20 * 60,
  liveBufferWindow: 10750,
  getNewDataBeforeNextProgramBeginsInterval: 15 * 1000,
  waitToFetchLiveElementsTime: 20 * 1000,
  timeZoneOffset,
  instrumentationKey: settings.instrumentationKey,
  eeaPortability: settings.eeaPortability,
  snowplowCollectorUrl: settings.snowplowCollectorUrl,
  snowplowServiceId: {
    tv: ServiceID.Nrktv,
    radio: ServiceID.Nrkradio,
    super: ServiceID.Nrksuper,
  },
  debugging: settings.debugging,
};

export default config;
