export type ChromecastModel = 'gen1' | 'gen2' | 'gen3' | 'ultra' | 'audio' | 'touch' | 'android' | 'unknown';

export function getChromecastModel(): ChromecastModel {
  try {
    const context = cast.framework.CastReceiverContext.getInstance();
    const deviceCapabilities = context.getDeviceCapabilities();
    if (deviceCapabilities.touch_input_supported) {
      return 'touch'; // Google Nest
    }
    if (!deviceCapabilities.display_supported) {
      return 'audio'; // Chromecast Audio
    }
    if (/android/i.test(window.navigator.userAgent)) {
      return 'android'; // Android TV
    }
    if (isVideoLevelSupported(ChromecastVideoLevel.Level51)) {
      return 'ultra';
    }
    if (isVideoLevelSupported(ChromecastVideoLevel.Level42)) {
      return 'gen3';
    }
    if (isVideoLevelSupported(ChromecastVideoLevel.Level41)) {
      return deviceCapabilities.bluetooth_supported ? 'gen2' : 'gen1';
    }

    return 'unknown';
  } catch (e) {
    return 'unknown';
  }
}

// Supported Media for Google Cast https://developers.google.com/cast/docs/media
// Mime-types https://cconcolato.github.io/media-mime-support/
export enum ChromecastVideoLevel {
  Level41 = 'avc1.640029',
  Level42 = 'avc1.64002a',
  Level51 = 'hev1.1.6.L150.B0',
}

export function isVideoLevelSupported(level: ChromecastVideoLevel) {
  try {
    const context = cast.framework.CastReceiverContext.getInstance();
    return context.canDisplayType('video/mp4', level);
  } catch (e) {
    return false;
  }
}

export function getMaxVideoLevel() {
  if (isVideoLevelSupported(ChromecastVideoLevel.Level51)) {
    return '5.1';
  }
  if (isVideoLevelSupported(ChromecastVideoLevel.Level42)) {
    return '4.2';
  }
  if (isVideoLevelSupported(ChromecastVideoLevel.Level41)) {
    return '4.1';
  }
  return 'unknown';
}
