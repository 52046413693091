import { getLogger } from '../logging/logger';

interface HlsMediaPlaylist {
  start: number;
  end: number;
  endList: boolean;
  programTime?: number;
  mediaSequence: number;
  segmentDurations: number[];
  targetDuration: number;
  playlistType?: string;
  updatedTime: number;
  version?: number;
}

const logger = getLogger('HlsLiveTime');

/**
 * Keep track of time and seekable range for HLS live streams played by MPL.
 *
 * The Chromecast MPL player doesn't keep track of position 0 over time. When
 * seeking, position 0 is reset. And the HLS program date time is not provided
 * anywhere. The Chromecast's media.startAbsoluteTime doesn't represent the
 * media program time.
 *
 * `handleHlsMediaPlaylistUpdate()` must be registered as `manifestHandler` in
 * the `PlaybackConfig` for HLS live streams, but it can be always registered
 * and then `isActive()` must be checked to use this module correctly.
 *
 * XXX: `isActive()` doesn't know if Shaka is enabled for HLS streams or not.
 * It should only be used with MPL.
 */
export class HlsLiveTime {
  private playerManager: cast.framework.PlayerManager;
  private isLiveHlsStream = false;
  private hlsMediaPlaylist?: HlsMediaPlaylist;
  private currentTimeOffset = 0;

  constructor(playerManager: cast.framework.PlayerManager) {
    this.playerManager = playerManager;

    playerManager.addEventListener(cast.framework.events.EventType.PLAYER_LOADING, () => {
      const media = this.playerManager.getMediaInformation();

      this.hlsMediaPlaylist = undefined;
      this.currentTimeOffset = 0;
      this.isLiveHlsStream =
        media &&
        media.streamType === cast.framework.messages.StreamType.LIVE &&
        (media.contentType === 'application/vnd.apple.mpegurl' ||
          media.contentType.toLowerCase() === 'application/x-mpegurl');
      logger.log('Is live HLS stream:', this.isLiveHlsStream);
    });

    playerManager.addEventListener(cast.framework.events.EventType.MEDIA_FINISHED, () => {
      this.hlsMediaPlaylist = undefined;
    });

    playerManager.addEventListener(cast.framework.events.EventType.SEEKING, () => {
      if (!this.isLiveHlsStream) {
        return;
      }

      const liveSeekableRange = playerManager.getLiveSeekableRange();
      if (this.hlsMediaPlaylist !== undefined && liveSeekableRange !== undefined && liveSeekableRange !== null) {
        this.currentTimeOffset = this.hlsMediaPlaylist.start - (liveSeekableRange.start ?? 0);
      }
    });
  }

  /**
   * Returns true for live HLS streams. Note that it's currently unaware of
   * which player is used (Shaka vs MPL). It should return false if Shaka is
   * used.
   */
  isActive(): boolean {
    return this.isLiveHlsStream;
  }

  /**
   * Returns the actual current position in an HLS live stream, where position
   * 0 is fixed at the first segment in the first media playlist loaded at
   * start. In seconds.
   */
  getCurrentTime(): number {
    return this.playerManager.getCurrentTimeSec() + this.currentTimeOffset;
  }

  /**
   * Returns the current error of the Chromecast's current time for HLS live
   * streams. In seconds.
   */
  getCurrentTimeOffset(): number {
    return this.currentTimeOffset;
  }

  /**
   * Returns the actual live seekable range in an HLS live stream, where
   * position 0 is fixed at the first segment in the first media playlist
   * loaded at start. Start and end values are in seconds.
   */
  getLiveSeekableRange(): Required<cast.framework.messages.LiveSeekableRange> {
    const playlist = this.hlsMediaPlaylist;
    if (playlist === undefined) {
      return {
        start: 0,
        end: 0,
        isMovingWindow: false,
        isLiveDone: false,
      };
    }
    return {
      start: playlist.start,
      end: Math.max(playlist.start, playlist.end - 3 * playlist.targetDuration),
      isMovingWindow: playlist.start > 0,
      isLiveDone: playlist.endList,
    };
  }

  /**
   * Returns the program time of the HLS live stream at position 0 based on the
   * EXT-X-PROGRAM-DATE-TIME tag in the media playlist. In seconds.
   *
   * Only use if `isActive()` returns true.
   */
  getStartAbsoluteTime(): number {
    const playlist = this.hlsMediaPlaylist;

    if (playlist !== undefined) {
      if (playlist.programTime !== undefined) {
        return playlist.programTime - playlist.start;
      }

      // Rough estimate. The actual program time should always be present.
      return Date.now() / 1000 - playlist.targetDuration * 2 - playlist.end;
    }
    return 0;
  }

  /**
   * Process the just fetched HLS manifest (master or media playlist) and
   * extract all the information needed by this module.
   *
   * Only use if `isActive()` returns true.
   */
  handleHlsMediaPlaylistUpdate(manifest: string): void {
    const keyValueReader = readKeyValues(manifest);

    // Is the data actually HLS?
    const firstLine = keyValueReader.next();
    if (!firstLine.done && firstLine.value[0] !== '#EXTM3U') {
      logger.log('Manifest is not HLS:', manifest.substring(0, 100));
      return;
    }

    const playlist: HlsMediaPlaylist = {
      start: 0,
      end: 0,
      endList: false,
      mediaSequence: 0,
      segmentDurations: [],
      targetDuration: 0,
      updatedTime: Date.now() / 1000,
    };

    let duration = 0;

    for (const [key, value] of keyValueReader) {
      switch (key) {
        case '#EXTINF':
          const segmentDuration = Number.parseFloat(value);
          playlist.segmentDurations.push(segmentDuration);
          duration += segmentDuration;
          break;
        case '#EXT-X-VERSION':
          playlist.version = Number.parseInt(value, 10);
          break;
        case '#EXT-X-MEDIA-SEQUENCE':
          playlist.mediaSequence = Number.parseInt(value, 10);
          break;
        case '#EXT-X-PROGRAM-DATE-TIME':
          playlist.programTime = new Date(value).getTime() / 1000;
          break;
        case '#EXT-X-TARGETDURATION':
          playlist.targetDuration = Number.parseInt(value);
          break;
        case '#EXT-X-PLAYLIST-TYPE':
          playlist.playlistType = value;
          break;
        case '#EXT-X-ENDLIST':
          playlist.endList = true;
          break;
        default:
      }
    }

    if (duration === 0) {
      logger.log(`No segments. Probably master playlist:`, manifest.substring(0, 100));
      return;
    }

    // Use the media sequence numbers and the segment durations of the previous
    // media playlist to set correct start time of the updated media playlist,
    // keeping position 0 fixed.
    const previousPlaylist = this.hlsMediaPlaylist;
    if (previousPlaylist !== undefined) {
      const sequenceJump = playlist.mediaSequence - previousPlaylist.mediaSequence;
      playlist.start =
        previousPlaylist.start +
        previousPlaylist.segmentDurations.slice(0, sequenceJump).reduce((sum, duration) => sum + duration, 0);
    }
    playlist.end = playlist.start + duration;

    this.hlsMediaPlaylist = playlist;

    logger.log('HLS media playlist:', playlist);
  }
}

/**
 * Parse a text into key/value pairs by newlines and the first colon on each
 * line. The newlines, return characters and colons are not included in the
 * output. If a line doesn't have a colon, then the whole line is in the key
 * and the value becomes an empty string.
 */
function* readKeyValues(text: string) {
  let pair: [key: string, value: string] = ['', ''];
  let index = 0;
  let pairYielded = false;

  for (const c of text) {
    switch (c) {
      case '\n':
        yield pair;
        pairYielded = true;
        pair = ['', ''];
        index = 0;
        break;
      case '\r':
        break;
      case ':':
        if (index === 0) {
          index = 1;
          break;
        }
      default:
        pair[index] += c;
        pairYielded = false;
    }
  }

  if (!pairYielded) {
    yield pair;
  }
}
