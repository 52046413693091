import { getLogger } from '../logging/logger';
import { ProfileSetting } from '../psapi/contracts/profileSettings';

const PROFILESETTINGS_CACHE = 'nrktv-profilesettings';

type ProfileSettingsCache = Record<string, Record<string, ProfileSetting>>;

const logger = getLogger('cache');

export function addToCache(key: string | undefined, value: Record<string, ProfileSetting>) {
  if (storageAvailable('localStorage')) {
    const cache = getCache();
    if (cache && key) {
      cache[key] = value;
      setCache(cache);
    }
  } else {
    logger.log('localstorage not available');
  }
}

export function getFromCache(key: string | undefined): Partial<Record<string, ProfileSetting>> {
  if (storageAvailable('localStorage')) {
    const cache = getCache();
    if (cache && key && key in cache) {
      return cache[key];
    }
  }
  return {};
}

function getCache(): ProfileSettingsCache {
  if (storageAvailable('localStorage')) {
    const storage = localStorage.getItem(PROFILESETTINGS_CACHE);
    if (storage) {
      try {
        const parsed = JSON.parse(storage);
        return parsed ? parsed : {};
      } catch (error) {
        logger.warn(`Error: Unable to parse localstorage data from ${PROFILESETTINGS_CACHE}`);
        return {};
      }
    }
  }
  return {};
}

function setCache(cache: ProfileSettingsCache): void {
  if (storageAvailable('localStorage')) {
    localStorage.setItem(PROFILESETTINGS_CACHE, JSON.stringify(cache));
  }
}

function storageAvailable(type: string) {
  let storage;
  try {
    // @ts-expect-error ignore it
    storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}
