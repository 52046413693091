export default class Middlewares<T> {
  private middlewares: Array<(input?: T) => Promise<T>> = [];

  addMiddleware(mw: (input?: T) => Promise<T>) {
    this.middlewares.push(mw);
  }

  run(input?: T): Promise<T | void> {
    const cue = [...this.middlewares];

    return new Promise<T | void>((resolve, reject) => {
      function runNext(innerInput?: T) {
        const next = cue.shift();
        if (!next) {
          if (innerInput) {
            resolve(innerInput);
          } else {
            resolve();
          }
          return;
        }
        next(innerInput)
          .then((result) => {
            runNext(result);
          })
          .catch((e) => {
            reject(e);
          });
      }

      runNext(input);
    });
  }
}
