import { EpgOptions } from './loaders/Loader';
import { getLogger } from '../logging/logger';
import NRKLiveEpg from '../NRKLiveEpg';
import EpgTrackerEvents, { EpgTrackerEventArgs } from '../NRKLiveEpg/EpgTrackerEvents';
import { HlsLiveTime } from './hls-live-time';

const logger = getLogger('LiveEpgObserver');

export default class LiveEpgObserver {
  private playerManager: cast.framework.PlayerManager;
  private nrkLiveEpg: NRKLiveEpg;

  constructor(playerManager: cast.framework.PlayerManager, hlsLiveTime: HlsLiveTime) {
    this.playerManager = playerManager;

    this.nrkLiveEpg = new NRKLiveEpg({
      currentLiveTime: () => {
        const currentTime = hlsLiveTime.isActive()
          ? hlsLiveTime.getCurrentTime() + hlsLiveTime.getStartAbsoluteTime()
          : this.playerManager.getCurrentTimeSec() + this.playerManager.getStartAbsoluteTime();
        return new Date(currentTime * 1000);
      },
    });

    this.nrkLiveEpg.on(EpgTrackerEvents.LIVEPROGRAM_CHANGED, (prog) => {
      logger.log('EPG item', prog);
    });
    this.nrkLiveEpg.on(EpgTrackerEvents.EPG_UPDATED, (epg) => {
      logger.log('EPG update', epg);
    });

    this.playerManager.addEventListener(cast.framework.events.EventType.SEEKED, this.onPlayerManagerSeeked);
  }

  on<E extends EpgTrackerEvents>(event: E, listener: (...args: EpgTrackerEventArgs[E]) => void) {
    this.nrkLiveEpg.on(event, listener);
  }

  off<E extends EpgTrackerEvents>(event: E, listener: (...args: EpgTrackerEventArgs[E]) => void) {
    this.nrkLiveEpg.off(event, listener);
  }

  init(epgOptions: EpgOptions = {}) {
    logger.log('EPG init', epgOptions);
    const { channel } = epgOptions;
    if (!channel) {
      this.stop();
      return;
    }

    this.nrkLiveEpg.start(channel);
  }

  stop() {
    this.nrkLiveEpg.stop();
  }

  private onPlayerManagerSeeked = () => {
    this.nrkLiveEpg.resolve();
  };
}
